import React from 'react';
import {Chat} from "../../components";

const Messages = () => {
  return (
    <div>
      <div className="heading">
        <h1>TaskPro</h1>
        <h4>Messages</h4>
      </div>
      <Chat/>
    </div>
  )
};

export default Messages;
