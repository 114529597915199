import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { RRule, RRuleSet, rrulestr } from "rrule";
import useApi from "../../hook/useApi";
import checkicon from "../../images/check-icon.svg";
import personicon from "../../images/person-logo.png";
import listicon from "../../images/list-icon.svg";
import dateicon from "../../images/calender-icon.svg";
import reloadicon from "../../images/reload-icon.svg";
import projecticon from "../../images/project-icon.png";
import { toast } from "react-toastify";
import close from "../../images/close.png";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { weekdaysName } from "../../utils/constants";
import { getProjects } from "../Dashboard/actions";
import moment from "moment";

const Addtask = () => {
  const token = localStorage.getItem("token");
  const [isRecurringEnabled, setIsRecurringEnabled] = useState(false);
  const [reminder, setReminder] = useState(false);
  const project = useSelector((s) => s.project.project.data);
  const projects = useSelector((s) => s.dashboard.projects?.data);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [recurrenceType, setRecurrenceType] = useState("");
  const [daysInMonth, setDaysInMonth] = useState(31);
  const [loading, setLoading] = useState(false);
  const [recurrencePattern, setRecurrencePattern] = useState("daily");
  const [recurringDates, setRecurringDates] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [monthchecked, setMonthChecked] = useState(false);
  const [monthoptionchaecked, setMonthoptionchecked] = useState(false);
  const [yearlyoptionone, setYearlyoptionone] = useState(false);
  const [yearlyoptiontwo, setYearlyoptiontwo] = useState(false);
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [members, setmembers] = useState();
  const [projectData, setProjectData] = useState(null);
  const [projectId, setProjectId] = useState("");
  const [months, setMonths] = useState([]);
  const [errors, setErrors] = useState({});
  const monthsWithArray = Array.from({ length: 12 }, (_, i) => i + 1);
  useEffect(() => {
    const generateMonths = () => {
      const monthsArray = [];
      for (let i = 0; i < 12; i++) {
        const monthName = moment().month(i).format("MMMM");
        monthsArray.push(monthName);
      }
      setMonths(monthsArray);
    };

    generateMonths();
  }, []);
  const daysOfWeek = Array.from({ length: 7 }, (_, i) => ({
    value: i,
    label: moment().day(i).format("dddd"),
  }));

  useEffect(() => {
    if (selectedProject) {
      fetchData(
        "GET",
        `api/v1/project/${selectedProject}/get`,
        {},
        {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        }
      )
        .then((res) => {
          console.log(res, "res");
          setProjectId(res._id);
          setProjectData(res.members);
        })
        .catch((error) => {
          console.error("Failed to fetch project data:", error);
        });
    }
  }, [selectedProject]);
  React.useEffect(() => {
    dispatch(getProjects());
  }, []);
  const validate = () => {
    let newErrors = {};

    if (!taskDetails.title) {
      newErrors.title = "Title is required";
    }
    if (!taskDetails.description) {
      newErrors.description = "Description is required";
    }
      
    if (!taskDetails.recurrenceRule.dtstart) {
      newErrors.dtstart = 'Start date is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSelectChange = (event) => {
    const options = event.target.options;
    const selectedValues = [...selectedUsers];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedValues.push({
          id: options[i].value,
          name: options[i].text,
        });
      }
    }
    const selectedId = selectedValues.map((user) => user.id);
    console.log(selectedValues, selectedId, "selectedvalue");
    const idSet = new Set(selectedId);
    setmembers(selectedId);
    const uniqueData = Array.from(
      new Set(selectedValues.map((item) => item.id))
    ).map((id) => {
      return selectedValues.find((item) => item.id === id);
    });
    setSelectedUsers(uniqueData);
  };

  const handleSwitchChange = () => {
    setIsRecurringEnabled(!isRecurringEnabled);
  };
  const { fetchData } = useApi();
  const [taskDetails, setTaskDetails] = useState({
    title: "",
    description: "",
    project: projectId,
    members: [],
    endDate: "",
    recurrenceRule: {
      freq: "",
      byweekday: [],
      interval: null,
      dtstart: "",
      until: "",
      count: "",
      bysetpos: "",
      bymonthday: "",
    },
  });

  const { recurrenceRule } = taskDetails;
  const { freq, byweekday, interval, dtstart, until, bysetpos } =
    recurrenceRule;

  const handleProjectChange = (event) => {
    setSelectedProject(event.target.value);
    setSelectedUsers([]);
  };

  const hadlemonthoptionone = () => {
    setYearlyoptionone(true);
    setYearlyoptiontwo(false);
  };
  const hadlemonthoptiontwo = () => {
    setYearlyoptionone(false);
    setYearlyoptiontwo(true);
  };
  const hadlemonthoptionchaecked = () => {
    setMonthoptionchecked(true);
    setMonthChecked(false);
  };
  const handleMonthCheckedRadio = (e) => {
    setMonthChecked(true);
    setMonthoptionchecked(false);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTaskDetails({ ...taskDetails, [name]: value });
  };

  const handleRecurrenceChange = (pattern) => {
    setRecurrencePattern(pattern);

    setTaskDetails({
      ...taskDetails,
      recurrenceRule: {
        ...taskDetails.recurrenceRule,
        byweekday: [],
        freq: pattern.toUpperCase(),
      },
    });
  };

  const handleRecurrenceTypeChange = (type) => {
    setRecurrenceType(type);
    let updatedRecurrenceRule = {};

    if (type === "the") {
      updatedRecurrenceRule = {
        bymonthday: [],
        byweekday: [],
        bysetpos: "",
        bymonth: "",
        interval: null,
      };
    } else if (type === "every") {
      updatedRecurrenceRule = {
        byweekday: [],
        bysetpos: null,
        bymonth: null,
      };
    } else if (type === "every-this-month") {
      updatedRecurrenceRule = {
        byweekday: [],
        bysetpos: null,
      };
    }

    setTaskDetails((prevTaskDetails) => ({
      ...prevTaskDetails,
      recurrenceRule: {
        ...prevTaskDetails.recurrenceRule,
        ...updatedRecurrenceRule,
        freq: recurrencePattern.toUpperCase(),
      },
    }));
  };

  const handleRecurrenceInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name.startsWith("weekday-")) {
      const day = name.split("-")[1];
      setTaskDetails((prevDetails) => {
        const newFreqIndex = checked
          ? [...prevDetails.recurrenceRule.byweekday, day]
          : prevDetails.recurrenceRule.byweekday.filter((d) => d !== day);
        return {
          ...prevDetails,
          recurrenceRule: {
            ...prevDetails.recurrenceRule,
            byweekday: newFreqIndex,
          },
        };
      });
    } else {
      setTaskDetails((prevDetails) => ({
        ...prevDetails,
        recurrenceRule: {
          ...prevDetails.recurrenceRule,
          [name]:
            type === "radio"
              ? value
              : type === "checkbox"
              ? checked
              : name === "interval"
              ? Number(value)
              : value,
        },
      }));
    }

    if (type === "radio" && name === "rangeOfRecurrence") {
      setTaskDetails((prevDetails) => ({
        ...prevDetails,
        recurrenceRule: {
          ...prevDetails.recurrenceRule,
          until: value === "endBy" ? prevDetails.recurrenceRule.until : "",
          count: value === "endAfter" ? prevDetails.recurrenceRule.count : "",
        },
      }));
    }
  };

  const generateRecurringDates = () => {
    if (!freq || !dtstart) {
      return [];
    }

    const rule = new RRule({
      freq: RRule[freq],
      interval: parseInt(interval, 10),
      dtstart: new Date(dtstart),
      until: until ? new Date(until) : undefined,
      byweekday:
        Array.isArray(byweekday) &&
        byweekday.map((day) => {
          if (typeof day === "string") {
            return RRule[day.toUpperCase()];
          }
          return day;
        }),
    });
  };

  const handleRadioChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handlechaeckedChange = () => {
    setReminder(!reminder);
  };
  const handleMonthChange = (e) => {
    const selectedMonth = parseInt(e.target.value);

    switch (selectedMonth) {
      case 2:
        setDaysInMonth(28);
        break;
      case 4:
      case 6:
      case 9:
      case 11:
        setDaysInMonth(30);
        break;
      default:
        setDaysInMonth(31);
        break;
    }
  };

  const handleSubmit = async () => {
  
    setLoading(true);
    if (validate()) {
      let newRecurrenceRule = {
        ...recurrenceRule,
        byweekday: Array.isArray(recurrenceRule.byweekday)
          ? recurrenceRule.byweekday.map((day) => weekdaysName[day])
          : [],
      };
      delete taskDetails.recurrenceRule.rangeOfRecurrence;
      delete newRecurrenceRule.rangeOfRecurrence;

      generateRecurringDates();

      const newRecurrenceRuleData = Object.entries(
        newRecurrenceRule || {}
      ).reduce((acc, [key, value]) => {
        if (Array.isArray(value) ? value.length > 0 : value) {
          acc[key] = value;
        }
        return acc;
      }, {});

      const taskDetailsRecurrenceRuleData = Object.entries(
        taskDetails.recurrenceRule || {}
      ).reduce((acc, [key, value]) => {
        if (Array.isArray(value) ? value.length > 0 : value) {
          acc[key] = value;
        }
        return acc;
      }, {});

      try {
        const response = await fetchData(
          "POST",
          "api/v1/task",
          {
            title: taskDetails.title,
            description: taskDetails.description,
            project: selectedProject,
            members: members,
            endDate: taskDetails.endDate,
            board:1,
            recurrenceRule:
              newRecurrenceRuleData.freq === "WEEKLY"
                ? {
                    ...newRecurrenceRuleData,
                    ...(newRecurrenceRuleData?.count && {
                      count: Number(newRecurrenceRuleData?.count),
                    }),
                    ...(newRecurrenceRuleData?.bymonth && {
                      bymonth: Number(newRecurrenceRuleData?.bymonth),
                    }),
                    ...(newRecurrenceRuleData?.bymonthday && {
                      bymonthday: Number(newRecurrenceRuleData?.bymonthday),
                    }),
                  }
                : newRecurrenceRuleData.freq === "MONTHLY"
                ? {
                    ...taskDetailsRecurrenceRuleData,
                    ...(taskDetailsRecurrenceRuleData?.count && {
                      count: Number(taskDetailsRecurrenceRuleData?.count),
                    }),
                    ...(taskDetailsRecurrenceRuleData?.bymonth && {
                      bymonth: Number(taskDetailsRecurrenceRuleData?.bymonth),
                    }),
                    ...(taskDetailsRecurrenceRuleData?.bymonthday && {
                      bymonthday: Number(
                        taskDetailsRecurrenceRuleData?.bymonthday
                      ),
                    }),
                    ...(taskDetailsRecurrenceRuleData?.bysetpos && {
                      bysetpos: Number(taskDetailsRecurrenceRuleData?.bysetpos),
                    }),
                    ...(taskDetailsRecurrenceRuleData?.byweekday && {
                      byweekday: [
                        Number(taskDetailsRecurrenceRuleData?.byweekday),
                      ],
                    }),
                  }
                : newRecurrenceRuleData.freq === "YEARLY"
                ? {
                    ...taskDetailsRecurrenceRuleData,
                    ...(taskDetailsRecurrenceRuleData?.count && {
                      count: Number(taskDetailsRecurrenceRuleData?.count),
                    }),
                    ...(taskDetailsRecurrenceRuleData?.bymonth && {
                      bymonth: Number(taskDetailsRecurrenceRuleData?.bymonth),
                    }),
                    ...(taskDetailsRecurrenceRuleData?.bymonthday && {
                      bymonthday: null,
                    }),
                    ...(taskDetailsRecurrenceRuleData?.bysetpos && {
                      bysetpos: Number(taskDetailsRecurrenceRuleData?.bysetpos),
                    }),
                    ...(taskDetailsRecurrenceRuleData?.byweekday && {
                      byweekday: [
                        Number(taskDetailsRecurrenceRuleData?.byweekday),
                      ],
                    }),
                  }
                : {
                    ...taskDetailsRecurrenceRuleData,
                    ...(taskDetailsRecurrenceRuleData?.count && {
                      count: Number(taskDetailsRecurrenceRuleData?.count),
                    }),
                    ...(taskDetailsRecurrenceRuleData?.bymonth && {
                      bymonth: Number(taskDetailsRecurrenceRuleData?.bymonth),
                    }),
                    ...(taskDetailsRecurrenceRuleData?.bymonthday && {
                      bymonthday: null,
                    }),
                    ...(taskDetailsRecurrenceRuleData?.bysetpos && {
                      bysetpos: Number(taskDetailsRecurrenceRuleData?.bysetpos),
                    }),
                  },
          },
          {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          }
        );
        toast.success(`task is created successfully`);
        setLoading(false);
        navigate("/dashboard");
      } catch (error) {
        toast.error(`task is not created`);
        setLoading(false);
        if (error.message === "Server error") {
          setLoading(false);
          toast.error(`Server error occurred. Please try again later.`);
        } else {
          setLoading(false);
        }
      }
    }
  
  };

  const closemodal = () => {
    navigate("/dashboard");
  };

  return (
    <>
      <div>
        <div className="heading">
          <h1>TaskPro</h1>
        </div>

        <div className="ant-card ant-card-bordered addnew-card overflow-hidden mt-4">
          <div className="ant-card-body p-0">
            <div className="heading mb-0">
              <h2 className="fw-bold fs-4">
                Add New Task{" "}
                <button
                  style={{
                    border: "none",
                    background: "bottom",
                    float: "inline-end",
                  }}
                  onClick={closemodal}
                >
                  <img src={close} />
                </button>
              </h2>
              <p>Fill in the details below to create a new task</p>
            </div>
            <div className="addnew-content px-4 py-4">
              <div className="detail-row">
                <span className="icon pt-2">
                  <img src={checkicon} alt="" />
                </span>
                <Form.Control
                  type="text"
                  name="title"
                  className="taskname"
                  placeholder="Task Name"
                  value={taskDetails.title}
                  onChange={handleInputChange}
                />
               
              </div>
              {errors.title && <div className="error">{errors.title}</div>}
              <div className="detail-row mt-4">
                <span className="icon pt-2 mt-1">
                  <img style={{ width: "25px" }} src={projecticon} alt="" />
                </span>
                <div className="mt-1 d-flex align-items-center">
                  <span className="taskname">Project</span>
                  <Form.Select
                    aria-label="Select month"
                    style={{ width: "auto" }}
                    className="detail-row-date ms-2"
                    value={selectedProject}
                    onChange={handleProjectChange}
                  >
                    <option disabled value="">
                      Select
                    </option>
                    {Array.isArray(projects) &&
                      projects?.map((project) => (
                        <option key={project._id} value={project._id}>
                          {project.name}
                        </option>
                      ))}
                  </Form.Select>
                 
                </div>
              </div>
              <div className="detail-row mt-4">
                <span className="icon pt-2 mt-1">
                  <img style={{ width: "25px" }} src={personicon} alt="" />
                </span>
                <div className="mt-1 d-flex align-items-center">
                  <span className="taskname">Assignee</span>
                  <Form.Select
                    aria-label="Select month"
                    style={{ width: "50%" }}
                    className="detail-row-date ms-2"
                    onChange={handleSelectChange}
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    <option value=""></option>
                    {Array.isArray(projectData) &&
                      projectData?.map((project) => (
                        <option key={project.user._id} value={project.user._id}>
                          {project?.user?.firstName}
                        </option>
                      ))}
                  </Form.Select>
                  <div className="selected-users">
                    {selectedUsers.map((user) => (
                      <span className="selected-span" key={user.id}>
                        {user.name}
                      </span>
                    ))}{" "}
                  </div>
                </div>
              </div>
              <div className="detail-row mt-4">
                <span className="icon pt-2">
                  <img src={listicon} alt="" />
                </span>
                <Form.Control
                  as="textarea"
                  name="description"
                  className="taskname"
                  placeholder="Description"
                  value={taskDetails.description}
                  onChange={handleInputChange}
                />
             
              </div>
              {errors.description && (
                  <div className="error">{errors.description}</div>
                )}
              <div className="detail-row mt-4">
                <Form.Check
                  type="checkbox"
                  id="default-checkbox"
                  label="Set Reminder"
                  className="checkbox"
                  checked={reminder}
                  onChange={handlechaeckedChange}
                />
              </div>
              {reminder && (
                <>
                  <div className="detail-row mt-4">
                    <span className="icon pt-2 mt-1">
                      <img src={dateicon} alt="" />
                    </span>
                    <div className="mt-1 d-flex align-items-center">
                      <span className="detail-label">Date</span>
                      <Form.Control
                        type="date"
                        name="endDate"
                        className="detail-row-date ms-2"
                        value={taskDetails.endDate}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="detail-row mt-4">
                    <span className="icon">
                      <img src={reloadicon} alt="" />
                    </span>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label="Enable recurring task reminder"
                      className="enable-recuring-switch"
                      checked={isRecurringEnabled}
                      onChange={handleSwitchChange}
                    />
                  </div>
                </>
              )}

              {isRecurringEnabled && (
                <div className="recurrence-box">
                  <h3 className="section-title">Recurrence pattern</h3>
                  <div className="row mt-4 radio-tabs">
                    <div className="col" style={{ maxWidth: "150px" }}>
                      <Form.Check
                        type="radio"
                        id="daily"
                        name="recurrencePattern"
                        label="Daily"
                        className="mb-3"
                        onChange={() => handleRecurrenceChange("daily")}
                      />
                      <Form.Check
                        type="radio"
                        id="weekly"
                        name="recurrencePattern"
                        label="Weekly"
                        className="mb-3"
                        onChange={() => handleRecurrenceChange("weekly")}
                      />
                      <Form.Check
                        type="radio"
                        id="monthly"
                        name="recurrencePattern"
                        label="Monthly"
                        className="mb-3"
                        onChange={() => handleRecurrenceChange("monthly")}
                      />
                      <Form.Check
                        type="radio"
                        id="yearly"
                        name="recurrencePattern"
                        label="Yearly"
                        onChange={() => handleRecurrenceChange("yearly")}
                      />
                    </div>
                    <div className="col right">
                      {recurrencePattern === "daily" && (
                        <>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Form.Check
                              type="radio"
                              name="dailyRecurrence"
                              id="dailyRecurrence"
                              label="Recur Every"
                              checked={taskDetails.recurrenceRule.interval}
                              onChange={() => {
                                setTaskDetails((prevDetails) => ({
                                  ...prevDetails,
                                  recurrenceRule: {
                                    ...prevDetails.recurrenceRule,
                                    interval: 1,
                                    byweekday: [],
                                    bymonthday: null,
                                    bymonth: null,
                                    bysetpos: null,
                                  },
                                }));
                              }}
                            />
                            <Form.Control
                              type="number"
                              name="interval"
                              className="ms-2"
                              placeholder="0"
                              max={365}
                              value={taskDetails.recurrenceRule.interval}
                              onChange={handleRecurrenceInputChange}
                              style={{
                                display: "inline",
                                border: "1.3px solid #1890ffe3",
                                paddingLeft: "12px",
                                width: "70px",
                              }}
                              disabled={
                                taskDetails.recurrenceRule.interval <= 0
                              }
                            />
                            day(s)
                          </div>
                          <div>
                            <Form.Check
                              type="radio"
                              name="dailyRecurrence"
                              id="everyWeekDay"
                              label="Every Week Day"
                              checked={
                                !!(
                                  taskDetails.recurrenceRule.byweekday &&
                                  taskDetails.recurrenceRule.byweekday.length >
                                    0
                                )
                              }
                              onChange={() => {
                                setTaskDetails((prevDetails) => ({
                                  ...prevDetails,
                                  recurrenceRule: {
                                    ...prevDetails.recurrenceRule,
                                    interval: null,
                                    bysetpos: null,
                                    bymonth: null,
                                    byweekday: [1, 2, 3, 4, 5, 6, 0],
                                  },
                                }));
                              }}
                            />
                          </div>
                        </>
                      )}
                      {recurrencePattern === "weekly" && (
                        <div className="col-11">
                          <label
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Form.Check
                              type="radio"
                              id="recure-every"
                              checked={isChecked}
                              onChange={handleRadioChange}
                              label="Recur Every"
                            ></Form.Check>
                            <Form.Control
                              type="number"
                              name="interval"
                              className="ms-2"
                              placeholder="0"
                              disabled={!isChecked}
                              value={taskDetails.recurrenceRule.interval}
                              onChange={handleRecurrenceInputChange}
                              style={{
                                display: "inline",
                                border: "1.3px solid #1890ffe3",
                                paddingLeft: "12px",
                                width: "50px",
                                marginRight: "9px",
                              }}
                            />
                            week(s) on:
                          </label>
                          <div style={{ display: "ruby", marginLeft: "40px" }}>
                            <Form.Check
                              type="checkbox"
                              id="weekday-MO"
                              name="weekday-MO"
                              label="Monday"
                              disabled={!isChecked}
                              className="me-2"
                              checked={byweekday?.includes("MO")}
                              onChange={handleRecurrenceInputChange}
                            />
                            <Form.Check
                              type="checkbox"
                              id="weekday-TU"
                              name="weekday-TU"
                              label="Tuesday"
                              className="me-2"
                              disabled={!isChecked}
                              checked={byweekday?.includes("TU")}
                              onChange={handleRecurrenceInputChange}
                            />
                            <Form.Check
                              type="checkbox"
                              id="weekday-WE"
                              name="weekday-WE"
                              label="Wednesday"
                              className="me-2"
                              disabled={!isChecked}
                              checked={byweekday?.includes("WE")}
                              onChange={handleRecurrenceInputChange}
                            />
                            <Form.Check
                              type="checkbox"
                              id="weekday-TH"
                              name="weekday-TH"
                              label="Thursday"
                              className="me-2"
                              disabled={!isChecked}
                              checked={byweekday?.includes("TH")}
                              onChange={handleRecurrenceInputChange}
                            />
                            <Form.Check
                              type="checkbox"
                              id="weekday-FR"
                              name="weekday-FR"
                              label="Friday"
                              className="me-2"
                              disabled={!isChecked}
                              checked={byweekday?.includes("FR")}
                              onChange={handleRecurrenceInputChange}
                            />
                            <Form.Check
                              type="checkbox"
                              id="weekday-SA"
                              name="weekday-SA"
                              label="Saturday"
                              className="me-2"
                              disabled={!isChecked}
                              checked={byweekday?.includes("SA")}
                              onChange={handleRecurrenceInputChange}
                            />
                            <Form.Check
                              type="checkbox"
                              id="weekday-SU"
                              name="weekday-SU"
                              label="Sunday"
                              className="me-2"
                              disabled={!isChecked}
                              checked={byweekday?.includes("SU")}
                              onChange={handleRecurrenceInputChange}
                            />
                          </div>
                        </div>
                      )}
                      {recurrencePattern === "monthly" && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "16px",
                          }}
                        >
                          {/* Option 1: Day */}
                          <div
                            className=""
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <Form.Check
                              type="radio"
                              label="Day"
                              value={monthchecked}
                              checked={recurrenceType === "every-this-month"}
                              onChange={() => {
                                handleRecurrenceTypeChange("every-this-month");
                                handleMonthCheckedRadio();
                              }}
                            />
                            <Form.Control
                              type="number"
                              name="bymonthday"
                              className=""
                              disabled={!monthchecked}
                              value={taskDetails.recurrenceRule.bymonthday}
                              onChange={handleRecurrenceInputChange}
                              placeholder="0"
                              style={{
                                border: "1.3px solid #1890ffe3",
                                width: "60px",
                              }}
                              max={daysInMonth}
                            />
                            <label>of Every</label>
                            <Form.Select
                              aria-label="Select month"
                              style={{ width: "10%" }}
                              name="bymonth"
                              disabled={!monthchecked}
                              value={taskDetails.recurrenceRule.bymonth}
                              onChange={(e) => {
                                handleMonthChange(e);
                                handleRecurrenceInputChange(e);
                              }}
                            >
                              <option disabled value="">
                                0
                              </option>
                              {monthsWithArray.map((month) => (
                                <option key={month} value={month}>
                                  {month}
                                </option>
                              ))}
                            </Form.Select>
                            <label>Month(s)</label>
                          </div>
                          {/* Option 2: Day */}

                          <div
                            className=""
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            <Form.Check
                              type="radio"
                              label="The"
                              value={monthoptionchaecked}
                              checked={recurrenceType === "the"}
                              onChange={() => {
                                handleRecurrenceTypeChange("the");
                                hadlemonthoptionchaecked();
                              }}
                            />
                            <Form.Select
                              aria-label="Select a date"
                              style={{ width: "25%" }}
                              name="bysetpos"
                              disabled={!monthoptionchaecked}
                              value={taskDetails.recurrenceRule.bysetpos}
                              onChange={handleRecurrenceInputChange}
                            >
                              <option disabled value="">
                                Select
                              </option>
                              <option value="1">first</option>
                              <option value="2">second</option>
                              <option value="3">third</option>
                              <option value="4">fourth</option>
                            </Form.Select>
                            <Form.Select
                              aria-label="Select a day"
                              style={{ width: "25%" }}
                              name="byweekday"
                              disabled={!monthoptionchaecked}
                              value={taskDetails.recurrenceRule.byweekday}
                              onChange={handleRecurrenceInputChange}
                            >
                              {" "}
                              <option disabled value="">
                                Select
                              </option>
                              {daysOfWeek.map((day) => (
                                <option key={day.value} value={day.value}>
                                  {day.label}
                                </option>
                              ))}
                            </Form.Select>
                            <label>of Every</label>
                            <Form.Select
                              aria-label="Select month"
                              style={{ width: "20%" }}
                              name="bymonth"
                              disabled={!monthoptionchaecked}
                              value={taskDetails.recurrenceRule.bymonth}
                              onChange={handleRecurrenceInputChange}
                            >
                              <option disabled value="">
                                Select
                              </option>
                              {months.map((month, index) => (
                                <option key={index} value={index + 1}>
                                  {month}
                                </option>
                              ))}
                            </Form.Select>
                            Month(s)
                          </div>
                        </div>
                      )}

                      {recurrencePattern === "yearly" && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "16px",
                          }}
                        >
                          {/* Option 1:  */}
                          <div
                            className=""
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <Form.Check
                              type="radio"
                              label="Recure every"
                              value={yearlyoptionone}
                              checked={recurrenceType === "every"}
                              onChange={() => {
                                handleRecurrenceTypeChange("every");
                                hadlemonthoptionone();
                              }}
                            />
                            <Form.Control
                              type="number"
                              placeholder="0"
                              name="interval"
                              className=""
                              value={taskDetails.recurrenceRule.interval}
                              onChange={handleRecurrenceInputChange}
                              disabled={!yearlyoptionone}
                              style={{
                                display: "inline",
                                border: "1.3px solid #1890ffe3",
                                paddingLeft: "12px",
                                width: "50px",
                              }}
                            ></Form.Control>
                            <label>year(s)</label>
                          </div>

                          {/* Option 1: Day */}
                          <div className="">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              <Form.Check
                                type="radio"
                                label="The"
                                value={yearlyoptiontwo}
                                checked={recurrenceType === "the"}
                                onChange={() => {
                                  handleRecurrenceTypeChange("the");
                                  hadlemonthoptiontwo();
                                }}
                              />
                              <Form.Select
                                aria-label="Select ordinal"
                                style={{ width: "20%" }}
                                name="bysetpos"
                                disabled={!yearlyoptiontwo}
                                value={taskDetails.recurrenceRule.bysetpos}
                                onChange={handleRecurrenceInputChange}
                              >
                                <option disabled value="">
                                  Select
                                </option>
                                <option value="1">first</option>
                                <option value="2">second</option>
                                <option value="3">third</option>
                                <option value="4">fourth</option>
                              </Form.Select>
                              <Form.Select
                                aria-label="Select day of week"
                                style={{ width: "20%" }}
                                name="byweekday"
                                disabled={!yearlyoptiontwo}
                                value={taskDetails.recurrenceRule.byweekday}
                                onChange={handleRecurrenceInputChange}
                              >
                                <option disabled value="">
                                  Select
                                </option>
                                {daysOfWeek.map((day) => (
                                  <option key={day.value} value={day.value}>
                                    {day.label}
                                  </option>
                                ))}
                              </Form.Select>
                              <label> of </label>
                              <Form.Select
                                aria-label="Select month"
                                style={{ width: "20%" }}
                                name="bymonth"
                                disabled={!yearlyoptiontwo}
                                value={taskDetails.recurrenceRule.bymonth}
                                onChange={handleRecurrenceInputChange}
                              >
                                <option disabled value="">
                                  Select
                                </option>
                                {months.map((month, index) => (
                                  <option key={index} value={index + 1}>
                                    {month}
                                  </option>
                                ))}
                              </Form.Select>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <h3 className="section-title mt-5">Range of recurrence</h3>
                  <div className="row radio-tabs mt-3">
                    <div className="col-4">
                      <div className="detail-row">
                        <span className="icon pt-2 mt-1">
                          <img src={dateicon} alt="" />
                        </span>
                        <div className="mt-1 d-flex align-items-center">
                          <span className="detail-label">Start</span>
                          <Form.Control
                            type="date"
                            name="dtstart"
                            className="detail-row-date ms-2"
                            value={taskDetails.recurrenceRule.dtstart}
                            onChange={handleRecurrenceInputChange}
                          />
                          
                        </div>
                      </div>
                      {errors.dtstart && <div style={{marginTop:'10px'}} className="error">{errors.dtstart}</div>}
                    </div>
                    <div className="col-6 ps-lg-4">
                      <div className="right">
                        <div className="form-check d-flex flex-wrap mt-2">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="rangeOfRecurrence"
                            id="endBy"
                            value="endBy"
                            checked={
                              taskDetails.recurrenceRule.rangeOfRecurrence ===
                              "endBy"
                            }
                            onChange={handleRecurrenceInputChange}
                          />
                          <label className="form-check-label" htmlFor="endBy">
                            End by:
                            <Form.Control
                              type="date"
                              name="until"
                              className="ms-2"
                              style={{ maxWidth: "150px", display: "inline" }}
                              value={taskDetails.recurrenceRule.until}
                              onChange={handleRecurrenceInputChange}
                              disabled={
                                taskDetails.recurrenceRule.rangeOfRecurrence !==
                                "endBy"
                              }
                            />
                          </label>
                        </div>

                        <div className="form-check d-flex flex-wrap mt-4 pt-1">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="rangeOfRecurrence"
                            id="endAfter"
                            value="endAfter"
                            checked={
                              taskDetails.recurrenceRule.rangeOfRecurrence ===
                              "endAfter"
                            }
                            onChange={handleRecurrenceInputChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="endAfter"
                          >
                            End after:
                            <Form.Control
                              type="number"
                              name="count"
                              className="ms-2"
                              placeholder="0"
                              value={taskDetails.recurrenceRule.count}
                              onChange={handleRecurrenceInputChange}
                              style={{ maxWidth: "60px", display: "inline" }}
                              disabled={
                                taskDetails.recurrenceRule.rangeOfRecurrence !==
                                "endAfter"
                              }
                            />
                            occurrences
                          </label>
                        </div>

                        <div className="form-check d-flex flex-wrap mt-3 pt-1">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="rangeOfRecurrence"
                            id="noEndDate"
                            value="noEndDate"
                            checked={
                              taskDetails.recurrenceRule.rangeOfRecurrence ===
                              "noEndDate"
                            }
                            onChange={handleRecurrenceInputChange}
                          />
                           
                          <label
                            className="form-check-label"
                            htmlFor="noEndDate"
                          >
                            No end date
                            <Form.Control
                              type="number"
                              name="until"
                              className="ms-2"
                              placeholder=""
                              onChange={handleRecurrenceInputChange}
                              style={{ maxWidth: "60px", display: "none" }}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="addnew-footer p-4">
              <button onClick={handleSubmit} className="btn btn-theme">
                {loading ? "Loading..." : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Addtask;
