export const weekdaysName = {
    "MO": 0 ,
     "TU": 1,
   "WE": 2 ,
   "TH": 3,
   "FR": 4,
    "SA": 5 ,
   "SU" :6 
}

export const freqMapping = {
    "yearly": 0,
    "monthly": 1,
    "weekly": 2,
    "daily": 3
  };
