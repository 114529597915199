
export const GET_PROJECT = 'PROJECT/GET_PROJECT';
export const GET_PROJECT_SUCCESS = 'PROJECT/GET_PROJECT_SUCCESS';

export const GET_PROJECT_TASKS = 'PROJECT/GET_PROJECT_TASKS';
export const GET_PROJECT_TASKS_SUCCESS = 'PROJECT/GET_PROJECT_TASKS_SUCCESS';

export const GET_ARCHIVED_TASKS = 'PROJECT/GET_ARCHIVED_TASKS';
export const GET_ARCHIVED_TASKS_SUCCESS = 'PROJECT/GET_ARCHIVED_TASKS_SUCCESS';

export const UPDATE_PROJECT_CALENDAR = 'PROJECT/UPDATE_PROJECT_CALENDAR';
export const UPDATE_PROJECT_CALENDAR_SUCCESS = 'PROJECT/UPDATE_PROJECT_CALENDAR_SUCCESS';

export const UPDATE_TASK = 'PROJECT/UPDATE_TASK';

export const NEW_TASK = 'PROJECT/NEW_TASK';
export const NEW_TASK_SUCCESS = 'PROJECT/NEW_TASK_SUCCESS';

export const GET_PROJECT_HISTORY = 'PROJECT/GET_PROJECT_HISTORY';

export const ADD_MEMBER = 'PROJECT/ADD_MEMBER';
export const ADD_MEMBER_SUCCESS = 'PROJECT/ADD_MEMBER_SUCCESS';

export const REMOVE_MEMBER = 'PROJECT/REMOVE_MEMBER';

export const GET_TASK = 'PROJECT/GET_TASK';
export const GET_TASK_SUCCESS = 'PROJECT/GET_TASK_SUCCESS';
export const GET_TASK_ERROR = 'PROJECT/GET_TASK_ERROR';

export const UPDATE_PROJECT_LOGO = 'PROJECT/UPDATE_PROJECT_LOGO';
export const UPDATE_PROJECT_LOGO_SUCCESS = 'PROJECT/UPDATE_PROJECT_LOGO_SUCCESS';

export const UPDATE_PROJECT = 'PROJECT/UPDATE_PROJECT';

export const MUTE_PROJECT = 'PROJECT/MUTE_PROJECT';
export const MUTE_PROJECT_SUCCESS = 'PROJECT/MUTE_PROJECT_SUCCESS';

export const ARCHIVE_TASK = 'PROJECT/ARCHIVE_TASK';
export const UNARCHIVE_TASK = 'PROJECT/UNARCHIVE_TASK';
export const DELETE_TASK = 'PROJECT/DELETE_TASK';

export const DUPLICATE_BOARD = 'PROJECT/DUPLICATE_BOARD';

export const GET_TODO = 'PROJECT/GET_TODO';
export const GET_TODO_SUCCESS = 'PROJECT/GET_TODO_SUCCESS';
export const CREATE_TODO = 'USER/CREATE_TODO';
export const CREATE_TODO_SUCCESS = 'USER/CREATE_TODO_SUCCESS';
export const UPDATE_TODO = 'USER/UPDATE_TODO';
export const DELETE_TODO = 'USER/DELETE_TODO';

export const GET_NOTES = 'PROJECT/GET_NOTES';
export const GET_NOTES_SUCCESS = 'PROJECT/GET_NOTES_SUCCESS';
export const CREATE_NOTE = 'USER/CREATE_NOTE';
export const CREATE_NOTE_SUCCESS = 'USER/CREATE_NOTE_SUCCESS';
export const UPDATE_NOTES = 'USER/UPDATE_NOTES';
export const DELETE_NOTE = 'USER/DELETE_NOTE';

export const UPDATE_BOARDS = 'PROJECT/UPDATE_BOARDS';

export const LEAVE_PROJECT = 'PROJECT/LEAVE_PROJECT';

export const ACCEPT_INVITATION = 'user/ACCEPT_INVITATION';
export const DECLINE_INVITATION = 'user/DECLINE_INVITATION';

export const SET_TASK_ITEM = 'TASK/SET_TASK_ITEM';
export const SET_TASK_DETAIL = 'TASK/SET_TASK_DETAIL';

export const CREATE_TASK_ATTACHMENT = 'TASK/CREATE_TASK_ATTACHMENT';
export const CREATE_TASK_ATTACHMENT_SUCCESS = 'TASK/CREATE_TASK_ATTACHMENT_SUCCESS';

export const CREATE_TODO_GROUP = 'TASK/CREATE_TODO_GROUP';
export const CREATE_TODO_GROUP_SUCCESS = 'TASK/CREATE_TODO_GROUP_SUCCESS';

export const CREATE_TASK_TODO = 'TASK/CREATE_TASK_TODO';
export const CREATE_TASK_TODO_SUCCESS = 'TASK/CREATE_TASK_TODO_SUCCESS';

export const UPDATE_TODO_GROUP = 'TASK/UPDATE_TODO_GROUP';

export const DELETE_TODO_GROUP = 'TASK/DELETE_TODO_GROUP';

export const UPDATE_TASK_TODO = 'TASK/UPDATE_TASK_TODO';

export const DELETE_TASK_TODO = 'TASK/DELETE_TASK_TODO';

export const DELETE_TASK_ATTACHMENT = 'TASK/DELETE_TASK_ATTACHMENT';

export const UPDATE_MULTIPLE_TASK = 'TASK/UPDATE_MULTIPLE_TASK';

export const CREATE_COMMENT = 'TASK/CREATE_COMMENT';
export const CREATE_COMMENT_SUCCESS = 'TASK/CREATE_COMMENT_SUCCESS';

export const CREATE_COMMENT_ATTACHMENT = 'TASK/CREATE_COMMENT_ATTACHMENT';
export const CREATE_COMMENT_ATTACHMENT_SUCCESS = 'TASK/CREATE_COMMENT_ATTACHMENT_SUCCESS';

export const UPDATE_COMMENT = 'TASK/UPDATE_COMMENT';
export const DELETE_COMMENT_ATTACHMENT = 'TASK/DELETE_COMMENT_ATTACHMENT';
export const DELETE_COMMENT = 'TASK/DELETE_COMMENT';
