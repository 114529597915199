import React, { useState } from "react";
import useApi from "../../hook/useApi";
import { useEffect } from "react";
import Button from "../../components/basics/Button";

const Tasklist = () => {
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userId")
  const { fetchData } = useApi();
  const [tasks, setTasks] = useState([]);

  const fetchTaskList = async () => {
    try {
      const res = await fetchData(
        "GET",
        `api/v1/task/parent/task-list/${userId}`,
        {},
        {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        }
      );
      setTasks(res.data)
      console.log(res.data);
    } catch (error) {
      console.error("Error fetching task list:", error);
    }
  };

 
  const deleteTask = async (taskId) => {
    try {
      await fetchData(
        "DELETE",
        `api/v1/task/delete-task/${taskId}`,
        {},
        {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        }
      );
      await fetchTaskList();
    } catch (error) {
      console.error("Error deleting task:", error);
    }
  };
 

  useEffect(() => {
       fetchTaskList();
  }, []);

  return (
    <div className="p-6">
      <h2 className="text-3xl font-bold mb-4">Task List</h2>
      <ul
        style={{
          listStyleType: "none",
          padding: 0,
          margin: 0,
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: "16px",
        }}
      >
        {tasks.map((task) => (
          <li
            key={task.id}
            className="p-4 bg-white rounded shadow flex flex-col justify-between"
          >
            <div>
              <h3 className="text-xl font-semibold">{task.title}</h3>
              <p className="text-gray-600">{task.description}</p>
            </div>
            <Button
              onClick={() => deleteTask(task._id)}
              style={{ width: "auto" }}
            >
              Delete
            </Button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Tasklist;
