export const GET_CONVERSATIONS = "CHAT/GET_CONVERSATION";
export const GET_CONVERSATIONS_SUCCESS = "CHAT/GET_CONVERSATION_SUCCESS";

export const CREATE_CONVERSATION = "CHAT/CREATE_CONVERSATION";
export const CREATE_CONVERSATION_SUCCESS = "CHAT/CREATE_CONVERSATION_SUCCESS";

export const CREATE_GROUP = "CHAT/CREATE_GROUP";
export const CREATE_GROUP_SUCCESS = "CHAT/CREATE_GROUP_SUCCESS";

export const UPDATE_GROUP_IMAGE = "CHAT/UPDATE_GROUP_IMAGE";
export const UPDATE_GROUP_IMAGE_SUCCESS = "CHAT/UPDATE_GROUP_IMAGE_SUCCESS";

export const UPDATE_GROUP_SUCCESS = "CHAT/UPDATE_GROUP_SUCCESS";

export const CONVERSATION_REPLY = "CHAT/CONVERSATION_REPLY";
export const CONVERSATION_REPLY_SUCCESS = "CHAT/CONVERSATION_REPLY_SUCCESS";

export const SET_SEEN_MESSAGES = "CHAT/SET_SEEN_MESSAGES";
export const SET_SEEN_MESSAGES_SUCCESS = "CHAT/SET_SEEN_MESSAGES_SUCCESS";

export const DELETE_CONVERSATION = "CHAT/DELETE_CONVERSATION";
export const DELETE_CONVERSATION_SUCCESS = "CHAT/DELETE_CONVERSATION_SUCCESS";

export const EXIT_CONVERSATION = "CHAT/EXIT_CONVERSATION";
export const EXIT_CONVERSATION_SUCCESS = "CHAT/EXIT_CONVERSATION_SUCCESS";

export const REFRESH_CONVERSATION = "CHAT/REFRESH_CONVERSATION";

export const CLEAR_SENT_MESSAGE = "CHAT/CLEAR_SENT_MESSAGE";
